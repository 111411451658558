:root {
  --p-red: #fe4a49;
  --p-orange: #f47e3e;
  --p-yellow: #f8bf3a;
  --p-green: #7eb356;
  --p-teal: #50b99a;
  --p-blue: #009fb7;
  --p-violet: #7272ac;
  // --p-black: #130303;
  --p-black: hsl(0, 0%, 14%);
  --p-gray: hsl(0, 0%, 50%);

  --bright-green: hsl(128, 89%, 38%);

  --white-ter: hsl(0, 0%, 96%);
  --black-ter: hsl(0, 0%, 14%);
}

/* https://coolors.co/130303-fe4a49-f47e3e-f8bf3a-7eb356-50b99a-009fb7-7272ac */

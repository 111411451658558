.player-view {
  font-size: 1.25em;
  height: 100%;
  width: 100%;
  padding: 0.5em 0.5em;
  display: grid;
  justify-items: stretch;

  grid-template-columns: 1fr 85% 1fr;
  grid-template-rows: 3rem 1fr 80% 1fr;
  grid-column-gap: 3px;
  grid-row-gap: 3px;
}
.player-header {
  grid-area: 1 / 1 / 3 / 4;
}
.player-top-header {
  grid-area: 1 / 1 / 2 / 4;
  font-size: 1em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.player-sub-header {
  grid-area: 2 / 1 / 3 / 4;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.player-main {
  grid-area: 3 / 1 / 5 / 4;
}
.player-footer {
  grid-area: 4 / 1 / 5 / 4;
}
.player-center {
  grid-area: 3 / 2 / 4 / 3;
  align-self: center;
  justify-self: center;
}

.player-status-container {
  z-index: -1;
}
.player-status-text {
  width: 100%;
  text-align: center;
  font-size: 1.125em;
}

.player-interactive-panel {
  max-width: 1000px;
  margin: auto;
  padding: 1em 2%;
}

.player-prompt-text {
  font-size: 1.4em;
  text-align: center;
  width: 100%;
}

.player-view input {
  color: var(--black-ter);
  border: 0.2rem solid var(--p-black);
  font-size: 1.2em;

  &:hover {
    border-color: var(--p-teal);
  }
}

.vote-button {
  margin: 0.25rem 0rem;
  font-size: 1.25em;
}

.voting-options {
  margin: auto;
}

.player-info-edit {
  font-size: 1.2em;
}

.player-info-edit-buttons {
  display: flex;
  justify-content: space-between;
}

.inline-info {
  padding: 0 1em;
  font-size: 0.8em;
}

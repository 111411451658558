.reading-answers-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: space-around;
  row-gap: 1em;
  column-gap: 1em;

  margin-top: 2rem;

  > div {
    flex: 0 1 24%;
  }
}

.answer-card-container {
  color: var(--p-black);
  height: 14rem;
  display: grid;
  grid-template-columns: 1.5rem 1fr 1fr 1.5rem;
  grid-template-rows: 2.5rem 1fr 2.5rem;
  gap: 0 0;
  margin: 0.75rem 0rem;

  &.winning-answer-card-container {
  }

  &.losing-answer-card-container {
    color: hsl(0, 0, 20);
    opacity: 0.73;

    // background-image: linear-gradient);
  }
}

.answer-card {
  grid-column: 1 / 5;
  grid-row: 1 / 4;
  overflow: hidden;
  margin: 0.75rem;
  z-index: 0;

  > p.answer-text {
    flex: 0 0 100%;

    overflow-wrap: normal;
    hyphens: manual;
    text-align: center;
    font-size: 1.7em;
    // @media (min-aspect-ratio: 2/1) {
    //   font-size: 3.7vh;
    // }
  }

  &.winning-answer-card {
    border-color: var(--p-green);
    border-width: 0.8rem;
    border-radius: 1rem;
    font-weight: bold;
    box-shadow: none;
    // margin: 0.25rem;

    animation: border-color-spin 1.5s linear infinite,
      margin-pop 0.3s ease-in-out infinite alternate;
  }

  &.losing-answer-card {
    border-width: 0.15rem;
  }
}

.author {
  display: flex;
  justify-content: left;
  align-items: center;
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  z-index: 1;
  border-radius: 0.5rem;
  background-color: var(--p-blue);
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.1rem;
  background-color: var(--white-ter);
}

.answer-badge.voters {
  grid-column: 1 / 5;
  grid-row: 3 / 4;
  z-index: 1;
  margin: auto;
  background-color: var(--white-ter);
  overflow: break-word;
  text-align: center;
  padding-bottom: 0em;

  > .voter-icon {
    display: inline-block;
    padding: 0px 2px;
    margin: 0;
    // overflow: hidden;

    > p {
      font-size: 1em;
      font-weight: 400;
      margin: -0.5em 0em;
    }
  }
}

.answer-badge-emoji {
  font-size: 1.7rem;
}

.answer-badge {
  border-radius: 0.5rem;
  border: 0.125rem solid var(--p-black);
  overflow: hidden;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
}

.points {
  justify-self: right;
  grid-column: 3 / 5;
  grid-row: 1 / 2;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: right;
  align-content: center;
  flex-wrap: wrap;
  font-size: 1.25rem;
  > div {
    background-color: var(--white-ter);
  }

  > div.nonzero {
    background-color: var(--bright-green);
    color: white;
  }

  > div.shutout {
    background-color: var(--bright-green);
    color: white;
    font-size: 1.5rem;
    z-index: 3;
  }
}

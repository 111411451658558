.timer-bar {
  box-shadow: 3px 3px 0px var(--p-black);
  background-color: var(--white-ter);
  position: relative;
  overflow: hidden;
  height: 4vh;
  margin: 0.2vh 0;
  border-radius: 0.5rem;
  border: 0.2rem solid var(--p-black);
  width: 100%;

  > .timer-label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 2.5vh;
    font-weight: bold;
    color: var(--p-black);
  }
}

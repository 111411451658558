:root {
  box-sizing: border-box;
}

body {
  font-family: $family-sans-serif;
}

div.fun-bg {
  z-index: -5;
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100%;
}

@mixin theme-colorable($bgProperty, $fgProperty) {
  #{$bgProperty}: var(--white-ter);
  #{$fgProperty}: var(--black-ter);

  &.red {
    #{$bgProperty}: var(--p-red);
    #{$fgProperty}: var(--white-ter);
  }
  &.orange {
    #{$bgProperty}: var(--p-orange);
    #{$fgProperty}: var(--white-ter);
  }
  &.yellow {
    #{$bgProperty}: var(--p-yellow);
    #{$fgProperty}: var(--black-ter);
  }
  &.green {
    #{$bgProperty}: var(--p-green);
    #{$fgProperty}: var(--white-ter);
  }
  &.teal {
    #{$bgProperty}: var(--p-teal);
    #{$fgProperty}: var(--white-ter);
  }
  &.blue {
    #{$bgProperty}: var(--p-blue);
    #{$fgProperty}: var(--white-ter);
  }
  &.violet {
    #{$bgProperty}: var(--p-violet);
    #{$fgProperty}: var(--white-ter);
  }
  &.black {
    #{$bgProperty}: var(--black-ter);
    #{$fgProperty}: var(--white-ter);
  }
}

.cartoon-3d {
  box-shadow: 3px 3px 0px var(--p-black);
}

.cartoon-3d-mini {
  box-shadow: 2px 2px 0px var(--p-black);
}

.cartoon-3d-hoverable {
  @extend .cartoon-3d;
  position: relative;
  top: 0;
  left: 0;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0px 0px 0px var(--p-black);
    top: 3px;
    left: 3px;
  }

  &.perma-hovered {
    box-shadow: 0px 0px 0px var(--p-black);
    top: 3px;
    left: 3px;
  }
}

.cartoon-3d-mini-hoverable {
  @extend .cartoon-3d-hoverable;
  box-shadow: 2px 2px 0px var(--p-black);

  &:hover {
    box-shadow: 0px 0px 0px var(--p-black);
    top: 2px;
    left: 2px;
  }

  &.perma-hovered {
    box-shadow: 0px 0px 0px var(--p-black);
    top: 2px;
    left: 2px;
  }
}

.game-panel {
  @extend .cartoon-3d;
  border-radius: 0.5rem;
  border: 0.2rem solid var(--p-black);
  background-color: var(--white-ter);
  color: var(--p-black);
  overflow: hidden;
  padding: 0.5rem 0.5rem;

  &.static {
    color: var(--p-blue);
  }
}

.game-button {
  @extend .cartoon-3d-hoverable;
  border-radius: 0.5rem;
  border: 0.2rem solid var(--p-black);
  background-color: var(--white-ter);
  color: var(--p-black);
  font-weight: bold;
  font-size: 1.1rem;
  overflow: hidden;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  color: var(--white-ter);
  align-self: center;
  display: inline-block;

  @include theme-colorable(background-color, color);

  &.inverted {
    @include theme-colorable(color, background-color);
  }

  &.full-width {
    width: 100%;
  }

  &.inline {
    align-self: baseline;
    margin-bottom: -0.3rem;
  }

  &.disabled {
    color: var(--p-gray);
    box-shadow: 0px 0px 0px var(--p-gray);
    top: 3px;
    left: 3px;
    cursor: not-allowed;
  }
}

.mini-button {
  @extend .game-button;
  @extend .cartoon-3d-mini-hoverable;
  border: 0.125rem solid var(--p-black);
  border-radius: 0.25rem;
  padding: 0.125em 0.25em;
  font-size: 0.8rem;
}

input {
  color: var(--black-ter);
  border: 0.2rem solid var(--p-black);
  border-radius: 0.5rem;
  padding: 0.6rem 0.8rem;
  font-size: 1rem;

  &:hover {
    border-color: var(--p-teal);
  }
}

.help-tip {
  font-size: 0.65em;
  // padding: 0.3rem 0rem;
}

h1,
h2 {
  font-weight: bold;
}

h1 {
  font-size: 1.6em;
}

h2 {
  font-size: 1.3em;
}

h3 {
  font-size: 1.2em;
}

.custom-prompts-home-box {
  max-width: 1200px;
  margin: 4em auto;
}

.custom-prompts-form {
  max-width: 700px;
  margin: auto;
}

.logo-center-container {
  margin: 4em auto;
  text-align: center;
}

.custom-prompt-code-entry {
  font-size: 1.5em;
  padding: 0.5rem;
  text-transform: uppercase;
}

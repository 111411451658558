.options-panels-row {
  display: flex;

  > .game-panel {
    flex: 1 0;
  }
}

.custom-prompt-code-input-block {
  margin-right: 1rem;
}

.rules-panel {
  text-align: center;

  > div {
    margin-bottom: 0.5rem;
  }
}

@keyframes margin-pop {
  /* Change padding in opposite way so inner next doesn't change width */
  from {
    margin: 0.1rem;
    padding: 0.5rem;
  }
  to {
    margin: 0.45rem;
    padding: 0.15rem;
  }
}

@keyframes border-color-spin {
  0% {
    border-color: var(--p-red);
  }
  14% {
    border-color: var(--p-orange);
  }
  28% {
    border-color: var(--p-yellow);
  }
  42% {
    border-color: var(--p-green);
  }
  57% {
    border-color: var(--p-teal);
  }
  71% {
    border-color: var(--p-blue);
  }
  86% {
    border-color: var(--p-violet);
  }
  100% {
    border-color: var(--p-red);
  }
}

@keyframes score-color-shift {
  from {
    background-color: var(--p-green);
  }
  to {
    background-color: var(--p-teal);
  }
}

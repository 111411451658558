.lobby-player-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;

  > div {
    flex: 0 1 20%;
  }
}

.lobby-player-list-title {
  margin-bottom: 1em;
  > p {
    text-align: center;
    font-size: 2em;
  }
}

.lobby-player-list-item {
  display: flex;
  align-items: center;

  padding: 0 0.8rem 0 0.3rem;
  color: var(--black-ter);
  margin: 0.25rem;
  background-color: var(--white-ter);
  font-weight: bold;

  .nameplate-emoji {
    margin: 0 1rem;
    font-size: 3em;
  }
  .nameplate-name {
    flex-grow: 1;
    font-size: 1.6em;
  }

  .mini-button {
    transition: opacity 0.25s;
    opacity: 0;
  }

  &:hover .mini-button {
    opacity: 1;
  }
}

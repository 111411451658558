.host-view {
  height: 100%;
  width: 100%;
  padding: 1em 1em;
  display: grid;
  grid-template-columns: 5% repeat(4, 1fr) 5%;
  grid-template-rows: 10% 2fr repeat(2, 1fr) 5%;
  grid-column-gap: 3px;
  grid-row-gap: 3px;
}

.host-main {
  grid-area: 2 / 1 / 5 / 7;
  align-self: center;
}
.host-footer {
  grid-area: 5 / 1 / 6 / 7;
}
.host-header {
  grid-area: 1 / 1 / 2 / 7;
  align-self: center;

  display: flex;
  justify-content: space-between;
}
.host-lower {
  grid-area: 4 / 2 / 5 / 6;
}
.host-lower-left {
  grid-area: 4 / 2 / 5 / 3;
}
.host-lower-right {
  grid-area: 4 / 3 / 5 / 6;
}
.host-top-main {
  grid-area: 2 / 2 / 4 / 6;
  align-self: center;
}

.options-hud {
  display: block;
}

.admin-control-buttons {
  align-self: center;
}

.pop-in-enter {
  opacity: 0;
  transform: scale(0.8);
}
.pop-in-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms;
}
.pop-in-exit {
  opacity: 1;
}
.pop-in-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

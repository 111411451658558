.wrapped-text {
  white-space: normal;
  height: auto;
  overflow: wrap;
}

.flex-center-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold {
  font-weight: 700;
}

.push-left {
  margin-right: auto;
}

.inline-flex {
  display: inline-flex;
}

.on-top {
  z-index: 10;
}

.color-transition {
  transition: background-color 0.2s, color 0.2s;
}

.flex-level {
  display: flex;
  align-items: center;
  align-content: stretch;
  justify-content: space-between;

  &.center {
    justify-content: center;
  }

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.bottom {
    align-items: flex-end;
  }
  &.top {
    align-items: flex-start;
  }

  &.grow > * {
    flex-grow: 1;
  }

  &.shrink > * {
    flex-shrink: 1;
  }
}

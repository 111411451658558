.game-panel.leaderboard-first-box {
  background-color: #ffe373;
  height: 10rem;
}

.game-panel.leaderboard-second-box {
  background-color: #e9e9e9;
}

.game-panel.leaderboard-third-box {
  background-color: #ffc6af;
}

.leaderboard-low {
  margin-bottom: 0.5em !important;
}

.homepage-bg {
  z-index: -5;
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100%;

  background-color: var(--p-red);
  color: var(--p-violet);
}

.homepage {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  height: 100%;
}

.home-form {
  text-align: center;
  padding: 1em;
  margin: 0.5em auto;
  font-size: 1.2em;
  max-width: 500px;

  @media (max-width: 500px) {
    margin: 0.5em 0.5em;
  }

  input {
    color: var(--black-ter);
    border-radius: 0.5rem;
    border: 0.3rem solid var(--p-black);
    width: 30%;
    min-width: 130px;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    padding: 0.25em;
    margin: 0.5em 0em;
    border-color: var(--p-teal);
  }
  button.join-button {
    display: block;
    margin: auto;
    font-size: 1.25em;
  }

  p {
    margin: 0.25em 0;
  }
}

.bitwits-text-title {
  font-size: 4em;
  font-weight: bold;
  color: var(--white-ter);
}

.home-logo-container {
  margin: auto;
  max-width: 50%;
  padding: 5vh 0 1vh 0;
  @media (min-width: 700px) {
    max-width: 350px;
    padding-top: 12vh;
    padding-bottom: 5vh;
  }
}
